const Styles = theme => ({
    root: {
        boxShadow: 'none',
        [theme.breakpoints.between('0', '600')]: {
            boxShadow: '1px 1px 10px #EEEEEE',
        }
    },
    leftActions: {
        width: '60px',
        height: '60px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        visibility: 'hidden',
        [theme.breakpoints.between('0', '600')]: {
            visibility: 'visible',
        },
    },
    rightActions: {
        width: 'auto',
        height: '60px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
})

export default Styles;