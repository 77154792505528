const MuiPaper = {
    root: {
        padding: '35px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    elevation0: {
        borderRadius: '10px',
        boxShadow: '1px 1px 10px #EEEEEE'
    }
};

export default MuiPaper;