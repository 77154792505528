
const MuiDialog = {
    paper: {
        margin: '0 !important',
        padding: '10px 5px !important',
        '& .MuiDialogTitle-root': {
            width: '100%'
        },
        '& .MuiDialogContent-root': {
            width: '100%'
        },
        '& .MuiDialogActions-root': {
            width: '100%',
            '& .MuiButtonBase-root': {
                width: 'auto',
                backgroundColor: 'rgba(176, 25, 26, 1)'
            }
        }
    }
};

export default MuiDialog;
