import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';




const palette =  {
    black,
    white,
    primary: {
        main: 'rgba(176, 25, 26, 1)',
        light: 'rgba(176, 25, 26, 0.6)',     
        dark: 'rgba(105, 15, 15, 1)',
        contrastText: '#fff',
    },
    secondary: {
        main: '#1E1E1E',
        light: colors.red[500],
        dark: '#002884',
        contrastText: '#fff',
    },
    error: {
        contrastText: white,
        dark: colors.red[900],
        main: colors.red[700],
        light: colors.red[400]
    },
}

export default palette;