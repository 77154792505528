import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

const languages = ['en', 'pt'];

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    lng: 'pt',
    fallbackLng: 'pt',
    
    debug: false,
    whitelist: languages,

    interpolation: {
      escapeValue: false,
    }
  })
export default i18n;