import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/pt';
import styles from './styles';
import {
    withStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button
} from '@material-ui/core';


const PopupInfoApp = ({
    classes,
    open,
    data,
    onClose
}) => {


    const { t, i18n } = useTranslation();
    moment.locale(i18n.language);

    const handleClose = () => {
        onClose()
    }

    if (!data) {
        onClose()
    }

    return (
        <Dialog
            className={classes.root}
            open={open}
            onClose={handleClose}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle id="dialog-title" className={classes.dialogTitle}>
                {data.metadata.AppTitle}&nbsp;{data.metadata.AppVersion}
            </DialogTitle>
            <DialogContent>
                <DialogContentText className={classes.dialogContentText} id="dialog-description">
                    <div>
                        <span>{t('app notes')}</span>
                        <p>{data.metadata.AppDescription}</p>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

PopupInfoApp.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(PopupInfoApp)
