import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { Context } from './../../context';
import moment from 'moment';
import 'moment/locale/pt';
import clsx from 'clsx';
import styles from './styles';
import {
    withStyles,
    useTheme,
    useMediaQuery
} from '@material-ui/core';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import EditIcon from '@material-ui/icons/Edit';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import CreateNewFileIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import FolderIcon from './../../assets/icons/folder_icon.svg';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import InfoIcon from '@material-ui/icons/Info';
import * as util from './../../utils/util'
import { AuthContext } from '../../context/auth/auth-context.js';


const Tree = ({
    classes,
    node,
    onAction
}) => {


    const { isAdmin  } = useContext(AuthContext);
    const { state } = useContext(Context)
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'), {
        defaultMatches: true
    }); 
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    moment.locale(i18n.language);


    const actionsFolder = [
        { icon: <CreateNewFolderIcon />, name: t('new folder'), id: 'new folder' },
        { icon: <CreateNewFileIcon />, name: t('new file'), id: 'new file' },
        { icon: <DeleteIcon />, name: t('delete'), id: 'delete'}
    ];

    const actionsFile = [
        { icon: <InfoIcon />, name: t('info'), id: 'info' },
        { icon: <DeleteIcon />, name: t('delete'), id: 'delete' },
        { icon: <DownloadIcon />, name: t('download'), id: 'download' },
        { icon: <EditIcon />, name: t('edit'), id: 'edit' },
    ];

   
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(!open);
    };

    const handleActionDialAction = (node, action) => {
        onAction({ node, action})
    }

    const getIconFile = extension => {
        return util.getIconByExtension(extension)
    }

    const handleFileNameClick = (node) => {
        const action = 'download'
        onAction({ node, action})
    }

    const handleFreeAction = (node, action) => {
        onAction({ node, action })
    }




    const renderTree = (node) => (
        <TreeItem
            className={classes.treeItem}
            key={node.id}
            nodeId={node.id}
            label={
                <div className={classes.treeRow}>
                    {node.isFolder ? <img src={FolderIcon} alt="" style={{ width: '32px', height: '32px' }} /> : getIconFile(node.extension)}
                    {node.isFolder ? (
                        <span className={clsx({ [classes.name]: true })}>
                            {node.name}
                        </span>
                    ) : (
                            <span className={clsx( { [classes.name]: true,  [classes.nameFile]: true })} onClick={() => handleFileNameClick(node)}>
                                { node.name }
                            </span>   
                    )}
                    {!node.isFolder ? (
                        <div className={clsx({ [classes.updatedAtBox]: true }, { 'updateAtBox': true})}>
                            {t('update at')}:&nbsp;
                            <span>{isMobile ? moment(new Date(node.updatedAt)).format("L") : moment(new Date(node.updatedAt)).format("lll") }</span>
                        </div>
                    ) : null}
                    {(isAdmin && !isMobile) ? (
                        <div className="tree-row-box-action">
                            <SpeedDial
                                ariaLabel="speed-dial-actions"
                                className={classes.speedDial}
                                icon={<SpeedDialIcon />}
                                //icon={<SpeedDialIcon openIcon={<EditIcon />} />}
                                onClose={handleClose}
                                onOpen={null}
                                open={open}
                                direction="left"
                                onClick={(event) => {
                                    handleOpen()
                                    event.stopPropagation();
                                }}
                            >
                                {node.isFolder ? (
                                    actionsFolder.map((action) => (
                                        <SpeedDialAction
                                            key={action.name}
                                            icon={action.icon}
                                            tooltipTitle={action.name}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                handleActionDialAction(node, action.id)
                                            }}
                                        />
                                    ))
                                ) : (
                                        actionsFile.map((action) => (
                                            <SpeedDialAction
                                                key={action.name}
                                                icon={action.icon}
                                                tooltipTitle={action.name}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleActionDialAction(node, action.id)
                                                }}
                                            />
                                        ))
                                    )}
                            </SpeedDial>
                        </div>
                    ) : <div className="tree-row-box-free-action">
                            {!node.isFolder ? <div className={classes.freeAction}>
                                <InfoIcon onClick={() => handleFreeAction(node, 'info')}/>
                            </div> : null}
                        </div>
                    }
                </div>
            }
        >
            {Array.isArray(node.children) ? node.children.map((n) => renderTree(n)) : null}
        </TreeItem>
    );



    return (
        <TreeView
            className={classes.root}
            onNodeSelect={(event, value) => { return null }}
        >
            {renderTree(node)}
        </TreeView>
    )
}

Tree.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(Tree)

