import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import styles from './styles';
import {
    withStyles,
    Grid,
    LinearProgress,
    CircularProgress
} from '@material-ui/core';


const LoadingScreen = ({
    classes,
    type
}) => {


    const { t } = useTranslation();

    const renderProgress = () => {
        if (!type || type === 'linear') {
            return (
                <>
                    <LinearProgress className={classes.linearProgress} />
                    <h3>{t('loading')}...</h3>
                </>
            )
        } else {
            return (
                <>
                    <CircularProgress className={classes.circularProgress} color="primary"/>
                </>
            )
        }
    }

    return (
        <Grid
            className={clsx(
                { [classes.root]: true , [classes.overlay]: (type && type==="circular") } )}
            container
            justify="center"
            alignItems="center"
            style={{ textAlign: 'center', flexGrow: 1, height: '100%'}}>
            <Grid item>
                {renderProgress()}
            </Grid>
        </Grid>
    )
}

LoadingScreen.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(LoadingScreen)
