
import FileIcon from '@material-ui/icons/InsertDriveFile';
import ApkIcon from '../../assets/icons/apk_icon.svg';
import DmgIcon from '../../assets/icons/dmg_icon.svg';
import ExeIcon from '../../assets/icons/exe_icon.svg';
import IpaIcon from '../../assets/icons/ipa_icon.svg';
import ZipIcon from '../../assets/icons/zip_icon.svg';
import ImageIcon from '../../assets/icons/image_icon.svg';
import VideoIcon from '../../assets/icons/video_icon.svg';


export const listToTree = (list) => {
    let result = [];
    let level = { result };
    list.forEach(item => {
        let path = ''
        item.file.Key.split('/').reduce((r, name, i, a) => {
            path = path.length ? `${path}/${name}` : `${name}` 
            if (!r[name]) {
                const isFile = name.includes('.')
                let version = '';
                let extension = null
                if (isFile) {
                    version = item.AppVersion ? `v${item.AppVersion}` : ''
                    const index = name.lastIndexOf('.')
                    extension = name.substring(index + 1)
                }
                r[name] = { result: [] };
                r.result.push(
                    {
                        id: name,
                        name: isFile ? `${item.AppTitle} ${version}` : name,
                        children: r[name].result,
                        isFolder: !isFile,
                        extension: extension,
                        metadata: item,
                        path: path,
                        updatedAt: item.updatedAt,
                        createdAt: item.createdAt
                    })
            }
            r.result = sort(r.result)
            return r[name];
        }, level)
    })
    result = sortByName(result)
    // Remove files without parent folder
    return result.filter(item => !item.id.includes('.')) 
}

// - pastas "mãe" são por ordem alfabetica
// - pastas são sempre antes de ficheiros
// - pastas não "mãe" e ficheiros estão sempre ordenados por data(data mais recente aparece primeiro)


const sort = list => {
    let listSorted = list
    listSorted = sortFolderByDate(listSorted)
    listSorted = sortByType(listSorted)
    return listSorted
}

export const sortByName = (list) => {
    const listSorted = list.sort(function (a, b) {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
    })
    return listSorted
}

export const sortFolderByDate = (list) => {
    const listSorted = list.sort((a, b) => {
        return (new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    })
    return listSorted
}

const sortByType = (list) => {
    const listSorted = list.sort((a, b) => { return (a.isFolder === b.isFolder) ? 0 : a.isFolder ? -1 : 1 } )
    return listSorted
}


export const filterList = (list, property, query) => {
    if (!query.length) {
        return list
    }
    return list.filter(item => item[property].includes(query));
}


export const getIconByExtension = ext => {
    switch (ext) {
        case 'apk':
            return <img src={ApkIcon} alt="" style={{width: '32px', height: '32px', marginLeft: '5px'}}/>
        case 'ipa':
        case 'plist':
            return <img src={IpaIcon} alt="" style={{ width: '32px', height: '32px', marginLeft: '5px' }} />
        case 'exe':
            return <img src={ExeIcon} alt="" style={{ width: '32px', height: '32px', marginLeft: '5px' }}/>
        case 'dmg':
            return <img src={DmgIcon} alt="" style={{ width: '32px', height: '32px', marginLeft: '5px' }} />
        case 'zip':
            return <img src={ZipIcon} alt="" style={{ width: '32px', height: '32px', marginLeft: '5px' }}/>
            
        case 'jpeg':
        case 'png':
        case 'jpg':    
            return <img src={ImageIcon} alt="" style={{ width: '32px', height: '32px', marginLeft: '5px' }} />
        
        case 'mp4':
        case 'mkv':
        case 'wmv':
        case 'avi':
            return <img src={VideoIcon} alt="" style={{ width: '32px', height: '32px', marginLeft: '5px' }} />
        default:
            return <FileIcon />
    }
}