const classes =  theme => ({
    root: {
        padding: '30px',
    },
    dialogTitle: {
        '& h2': {
            fontWeight: 600,
            padding: '10px 0',
            color: 'rgba(176, 25, 26, 1)'
        }
    },
    dialogContentText: {
        '& span': {
            fontWeight: 700
        },
        '& p': {
            fontStyle: 'italic',
            fontSize: '0.8rem'
        }
    }
})
export default classes;