import React, { useRef, useState, useContext } from 'react';

import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import { List, MenuItem, ListItem, ListItemIcon, ListItemText, Divider, Avatar, IconButton, Popper, Paper, Grow, ClickAwayListener, Button } from '@material-ui/core';
import { Settings } from '@material-ui/icons';

import { AuthContext } from '../../context/auth/auth-context';

import Styles from './style';

const useStyles = makeStyles(Styles);

const SessionPopper = (props) => {

    const { attributes, signout } = useContext(AuthContext);
    const history = useHistory();
    const { t } = useTranslation();
    const classes = useStyles();
    const popperRef = useRef();
    const [popper, setPopper] = useState(false);

    const handlePopper = () => {
        setPopper(!popper);
    };

    return (
        <div className={classes.root}>
            <IconButton
                ref={popperRef}
                onClick={handlePopper}
                aria-controls={popper ? 'menu-list-grow' : undefined}
                aria-haspopup="true">
                <Avatar>J</Avatar>
            </IconButton>

            <Popper
                anchorEl={popperRef.current}
                placement="bottom-end"
                open={popper}
                className={classes.pooper}
                transition
                disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom-end' ? 'center top' : 'center bottom' }}>
                        <Paper elevation={0}>
                            <ClickAwayListener onClickAway={() => setPopper(false)}>
                                <List id="menu-list-grow" autoFocus={false} autoFocusItem={false}>
                                    <ListItem>
                                        <ListItemText primary={attributes.name ? attributes.name : attributes.email.split('@')[0]} secondary={attributes.email} />
                                    </ListItem>
                                    <Divider />
                                    <MenuItem className={classes.menuItem} onClick={() => history.push('/account/info')}>
                                        <ListItemIcon><Settings /></ListItemIcon>
                                        <ListItemText primary={t('session-popper-item-account')} />
                                    </MenuItem>
                                    <Button variant="outlined" className={classes.secundaryButton} onClick={signout}>{t('session-popper-btn-signout')}</Button>
                                </List>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
}

export default SessionPopper;