import React, { useEffect, useState, createContext } from 'react';
import * as AuthService from '../../service/api/auth/';

import { useNotifications } from '../../components/notification-snackbar';

export const AuthContext = createContext();

const AuthContextProvider = (props) => {

    const { triggerNotification } = useNotifications()
    const [state, setState] = useState({
        isLoading: true,
        isAuthenticated: false,
        cognitoUser: null,
        attributes: null,
        isAdmin: false
    });

    useEffect(() => {
        currentUserPoolUser();
    }, []);



    /**
     * Sign to the user account
     * @param {*} username email used to register the account.
     * @param {*} password password to access de account.
     * @returns 
     */
    const signin = async (username, password) => {
        try {
            const result = await AuthService.signIn(username, password);
            const { challengeName } = result
            if (challengeName) {
                setState({ isLoading: false, isAuthenticated: false, cognitoUser: result, attributes: null });
            } else {
                const isAdmin = await isUserAdmin()
                setState({ isLoading: false, isAuthenticated: true, isAdmin: isAdmin,   cognitoUser: result, attributes: result.attributes });
            }
            return result;
        } catch (error) {
            const errorCode = error.code
            switch (errorCode) {
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                case 'NotAuthorizedException':
                    triggerNotification("error", "notifications-signin-NotAuthorizedException-message", "notifications-signin-NotAuthorizedException-title");
                    break;
                case 'UserNotFoundException':
                    triggerNotification("error", "notifications-default-UserNotFoundException-message", "notifications-default-UserNotFoundException-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }


    const isUserAdmin = async () => {
        const result = await AuthService.isUserAdmin();
        let Listusergroups = result.signInUserSession.accessToken.payload['cognito:groups']
        if (!Listusergroups) {
            return false
        }
        const _isAdmin = Listusergroups.includes("Admins")
        return _isAdmin
}


    const signUp = async (data) => {
        try {
            const result = await AuthService.signUp(data);
            return result;
        } catch (error) {
            const errorCode = error.code
            switch (errorCode) {
                case 'NetworkError':
                    break;
                case 'NotAuthorizedException':
                    break;
                case 'UserNotFoundException':
                    break;
                default:
                    break;

            }
        }
    }

    const confirmSignUp = async (username, code) => {
        try {
            const result = await AuthService.confirmSignUp(username, code);
            return result;
        } catch (error) {
            const errorCode = error.code
            switch (errorCode) {
                case 'NetworkError':
                    break;
                case 'NotAuthorizedException':
                    break;
                case 'UserNotFoundException':
                    break;
                default:
                    break;
            }
        }
    }


    /**
     * Signout the session of the currect signin account.
     */
    const signout = async () => {
        await AuthService.signOut();
        setState({ isLoading: false, isAuthenticated: false, cognitoUser: null, attributes: null });
    };


    /**
     * Request password recover to access the account
     * @param {*} username email used to register the account.
     * @returns 
     */
    const forgotPassword = async (username) => {
        try {
            const result = await AuthService.forgotPassword(username);
            setState({ isLoading: false, isAuthenticated: false, cognitoUser: result, attributes: null });
            return result;
        } catch (error) {
            const errorCode = error.code
            switch (errorCode) {
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                case 'NotAuthorizedException':
                    triggerNotification("error", "notifications-default-NotAuthorizedException-message", "notifications-default-NotAuthorizedException-title");
                    break;
                case 'UserNotFoundException':
                    triggerNotification("error", "notifications-default-UserNotFoundException-message", "notifications-default-UserNotFoundException-title");
                    break;
                case 'InvalidParameterException':
                    triggerNotification("warning", "notifications-forgotpassword-InvalidParameterException-message", "notifications-forgotpassword-InvalidParameterException-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }


    /**
     * Password recover confirmation
     * @param {*} username email used to register the account.
     * @param {*} code verify code received via email to confirm the recover.
     * @param {*} newPassword new password to access the account.
     * @returns 
     */
    const forgotPasswordSubmit = async (username, code, newPassword) => {
        try {
            const result = await AuthService.forgotPasswordSubmit(username, code, newPassword);
            return result;
        } catch (error) {
            const errorCode = error.code
            switch (errorCode) {
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                case 'NotAuthorizedException':
                    triggerNotification("error", "notifications-default-NotAuthorizedException-message", "notifications-default-NotAuthorizedException-title");
                    break;
                case 'UserNotFoundException':
                    triggerNotification("error", "notifications-default-UserNotFoundException-message", "notifications-default-UserNotFoundException-title");
                    break;
                case 'InvalidParameterException':
                    triggerNotification("warning", "notifications-forgotpassword-InvalidParameterException-message", "notifications-forgotpassword-InvalidParameterException-title");
                    break;
                case 'ExpiredCodeException':
                    triggerNotification("warning", "notifications-forgotpassword-ExpiredCodeException-message", "notifications-forgotpassword-ExpiredCodeException-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }


    /**
     * Change currrent password to access the account
     * @param {*} oldPassword old password used to access the account.
     * @param {*} newPassword new password to access the account.
     * @returns 
     */
    const changePassword = async (oldPassword, newPassword) => {
        try {
            const result = await AuthService.changePassword(state.cognitoUser, oldPassword, newPassword);
            return result;
        } catch (error) {
            const errorCode = error.code
            switch (errorCode) {
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                case 'NotAuthorizedException':
                    triggerNotification("error", "notifications-changepassword-NotAuthorizedException-message", "notifications-changepassword-NotAuthorizedException-title");
                    break;
                case 'UserNotFoundException':
                    triggerNotification("error", "notifications-default-UserNotFoundException-message", "notifications-default-UserNotFoundException-title");
                    break;
                case 'LimitExceededException':
                    triggerNotification("error", "notifications-default-LimitExceededException-message", "notifications-default-LimitExceededException-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }

    /**
     * First login replace password
     * @param {*} newPassword new password to access the account.
     * @returns 
     */
    const completeNewPassword = async (newPassword) => {
        try {
            const result = await AuthService.completeNewPassword(state.cognitoUser, newPassword);
            setState({ isLoading: false, isAuthenticated: true, cognitoUser: result, attributes: result.attributes });
            return result;

        } catch (error) {
            const errorCode = error.code
            switch (errorCode) {
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                case 'NotAuthorizedException':
                    triggerNotification("error", "notifications-default-NotAuthorizedException-message", "notifications-default-NotAuthorizedException-title");
                    break;
                case 'UserNotFoundException':
                    triggerNotification("error", "notifications-default-UserNotFoundException-message", "notifications-default-UserNotFoundException-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }

    /**
     * Get the current user pool user information, if the session is valid.
     */
    const currentUserPoolUser = async () => {
        try {
            let result = await AuthService.currentUserPoolUser();
            if (result) {
                const isAdmin = await isUserAdmin()
                setState({ isAuthenticated: true, isLoading: false, isAdmin: isAdmin, cognitoUser: result, attributes: result.attributes });
            }
        } catch (error) {
            switch (error) {
                case 'No current user':
                    setState({ isAuthenticated: false, isLoading: false, cognitoUser: null, attributes: null });
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    };

    /**
     * Update user account attributes
     * @param {object} attributes object with diferente user attributes.
     * @returns 
     */
    const updateUserAttributes = async (attributes) => {
        try {
            const updatedAttributes = updateToKeyCustomAttribute(attributes);
            const result = await AuthService.updateUserAttributes(state.cognitoUser, updatedAttributes);
            return result;
        } catch (error) {
            const errorCode = error.code
            switch (errorCode) {
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                case 'NotAuthorizedException':
                    triggerNotification("error", "notifications-default-NotAuthorizedException-message", "notifications-default-NotAuthorizedException-title");
                    break;
                case 'UserNotFoundException':
                    triggerNotification("error", "notifications-default-UserNotFoundException-message", "notifications-default-UserNotFoundException-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }

    return (
        <AuthContext.Provider value={{ ...state, signin, isUserAdmin, signout, signUp, confirmSignUp, forgotPassword, forgotPasswordSubmit, changePassword, completeNewPassword, updateUserAttributes }}>
            { props.children}
        </AuthContext.Provider>
    )
}
export default AuthContextProvider;



//UTILS
function updateToKeyCustomAttribute(attributes) {
    let array = {}
    Object.entries(attributes).forEach(([key, value]) => {
        array = {
            ...array,
            [`custom:${key}`]: value.toString()
        }
    });
    return array;
}