import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import styles from './styles';
import { Context } from './../../context';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    withStyles,
    Container,
    Grid,
    FormControl,
    TextField,
    Button
} from '@material-ui/core';
import SubmitButton from '../../components/SubmitButton';
import FileDropzone from '../../components/FileDropzone';
import * as apiService from '../../utils/api';
import * as actionTypes from './../../actions';

import awsExports from "../../aws-exports";

const PUBLIC_STORAGE_URL = process.env.REACT_APP_PUBLIC_STORAGE_URL;
const PUBLIC_STORAGE_KEY = process.env.REACT_APP_PUBLIC_STORAGE_KEY;


const defaulInitialValueForm = {
    appTitle: '',
    path: '',
    appversion: '',
    appDescription: '',
    appLastUpdate: '',
    userGroup: ''
}


const CreateAppForm = ({
    classes
}) => {

    const { dispatch } = useContext(Context);
    const { t } = useTranslation();
    const history = useHistory();
    const [file, setFile] = useState(null)
    const [edit, setEdit] = useState(false)
    const [data, setData] = useState(null)

    



    const createNoIpaApp = async (values) => {
        let keyBucket = null;
        try {
            let filename = file ? `${new Date().getTime()}_${file.name}` : ''
            let path = values.path
            path = file ? path.concat('/', filename) : path
             keyBucket = file ? await apiService.uploadFile(path, file, null) : null;
            const newApp = {
                AppDescription: values.appDescription,
                AppLastUpdate: values.appLastUpdate,
                AppVersion: values.appversion,
                AppTitle: values.appTitle,
                file: {
                    Key: keyBucket ? keyBucket.key : null,
                    bucket: awsExports.aws_user_files_s3_bucket,
                    region: awsExports.aws_user_files_s3_bucket_region
                },
                usergroup: values.userGroup
            }
            await apiService.createApp(newApp)
            formik.resetForm()
            history.goBack()
        } catch (error) {
            if (keyBucket) {
                await apiService.removeItemStorage(keyBucket, null)
            }
            dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 0, title: t('error'), message: t(`app create error`) } })
        }
    }

    const createIpaApp = async (values) => { 
        let keyBucket = null;
        try {
            let filename = file ? `${new Date().getTime()}_${file.name}` : ''
            let path = values.path
            path = file ? path.concat('/', filename) : path
            keyBucket = file ? await apiService.uploadFile(path, file, PUBLIC_STORAGE_KEY) : null;
            const index = path.lastIndexOf('.')
            path = path.substring(0, index)
            path = `${path}.plist`
            const appName = values.appTitle
            let url = keyBucket ? `${PUBLIC_STORAGE_URL}/${keyBucket.key}` : ''
            url = encodeURI(url);
            const xml = await getPlistFile(url, appName)
            var xlmFile = new File([new Blob([xml], { type: 'text/xml' })], path);
            keyBucket = file ? await apiService.uploadFile(path, xlmFile, PUBLIC_STORAGE_KEY) : null;
            const newApp = {
                AppDescription: values.appDescription,
                AppLastUpdate: values.appLastUpdate,
                AppVersion: values.appversion,
                AppTitle: values.appTitle,
                file: {
                    Key: keyBucket ? keyBucket.key : null,
                    bucket: awsExports.aws_user_files_s3_bucket_public,
                    region: awsExports.aws_user_files_s3_bucket_region
                },
                usergroup: values.userGroup
            }
            await apiService.createApp(newApp)
            formik.resetForm()
            history.goBack()
        } catch (error) {
            if (keyBucket) {
                await apiService.removeItemStorage(keyBucket, PUBLIC_STORAGE_KEY)
            }
            dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 0, title: t('error'), message: t(`app create error`) } })
        }
    }

    const editNoIpaApp = async (data) => {
        try {
            let filename = file ? `${new Date().getTime()}_${file.name}` : ''
            let path = data.path
            path = file ? path.concat('/', filename) : path
            const keyBucket = file ? await apiService.uploadFile(path, file, null) : null;
            const appId = history.location.state.data.metadata.id
            const currentBucketKey = history.location.state.data.metadata.file.Key
            let appUpdated = {
                AppDescription: data.appDescription,
                AppLastUpdate: data.appLastUpdate,
                AppVersion: data.appversion,
                AppTitle: data.appTitle,
                id: appId
            }
            if (keyBucket) {
                appUpdated['file'] = {
                    Key: keyBucket.key,
                    bucket: awsExports.aws_user_files_s3_bucket_public,
                    region: awsExports.aws_user_files_s3_bucket_region
                }
            }

            // Update metadata in DB
            await apiService.updateApp(appUpdated)

            // Delete file in private storage
            //await apiService.removeItemStorage(currentBucketKey, null)
            dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 1, title: t('success'), message: t(`app updated successfully`) } })
            history.goBack()
        } catch (error) {
            dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 0, title: t('error'), message: t(`app updated error`) } })
        }
    }

    const editIpaApp = async (data) => {
        try {
            let filename = file ? `${new Date().getTime()}_${file.name}` : ''
            let path = data.path
            let keyBucket = null;
            if (file) {
                path = file ? path.concat('/', filename) : path
                keyBucket = file ? await apiService.uploadFile(path, file, PUBLIC_STORAGE_KEY) : null;
                const index = path.lastIndexOf('.')
                path = path.substring(0, index)
                path = `${path}.plist`
                const appName = data.appTitle
                let url = keyBucket ? `${PUBLIC_STORAGE_URL}/${keyBucket.key}` : ''  
                url = encodeURI(url);
                const xml = await getPlistFile(url, appName)
                var xlmFile = new File([new Blob([xml], { type: 'text/xml' })], path);
                keyBucket = file ? await apiService.uploadFile(path, xlmFile, PUBLIC_STORAGE_KEY) : null;
            }
            const appId = history.location.state.data.metadata.id
            const currentBucketKey = history.location.state.data.metadata.file.Key
            let appUpdated = {
                AppDescription: data.appDescription,
                AppLastUpdate: data.appLastUpdate,
                AppVersion: data.appversion,
                AppTitle: data.appTitle,
                id: appId
            }
            if (keyBucket) {
                appUpdated['file'] = {
                    Key: keyBucket.key,
                    bucket: awsExports.aws_user_files_s3_bucket_public,
                    region: awsExports.aws_user_files_s3_bucket_region
                }
            }
            // Update metadata in DB
            await apiService.updateApp(appUpdated)

            // Delete .plist in public storage
            await apiService.removeItemStorage(currentBucketKey, PUBLIC_STORAGE_KEY)

            const index = currentBucketKey.lastIndexOf('.')
            path = currentBucketKey.substring(0, index)
            path = `${path}.ipa`

             // Delete .ipa in public storage
            //await apiService.removeItemStorage(path, PUBLIC_STORAGE_KEY)
            dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 1, title: t('success'), message: t(`app updated successfully`) } })
            history.goBack()
        } catch (error) {
            dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 0, title: t('error'), message: t(`app updated error`) } })
        }
    }


    
    const getPlistFile = async (url, appName) => new Promise((result, error) => {
         fetch(`/files/generic.plist`)
            .then((r) => r.text())
            .then((data) => {
                const parser = new DOMParser();
                const xml = parser.parseFromString(data, "application/xml");
                const _url = xml.getElementsByTagName("array")[1].childNodes[1].getElementsByTagName('string')[1]
                const _appName = xml.getElementsByTagName("dict")[3].getElementsByTagName('string')[3]
                _url.textContent = url
                _appName.textContent = appName
                var s = new XMLSerializer();
                var newXmlStr = s.serializeToString(xml);
                result(newXmlStr)
            }).catch(() => error(null))
    })
    

    const createNewApplication = async (data) => {
        let extension = null;
        if (file) {
            const index = file.name.lastIndexOf('.')
            extension = file.name.substring(index + 1)
        }
        const isIpaFile = extension === 'ipa'
        if (isIpaFile) {
            await createIpaApp(data)
        } else {
            await createNoIpaApp(data)
        }
    }

    const editApplication = async (data) => {
        console.log(file)
        let extension = null;
        if (file) {
            const index = file.name.lastIndexOf('.')
            extension = file.name.substring(index + 1)
        } else {
            extension = data?.extension
        }
        const isIpaFile = extension === 'ipa'
        if (isIpaFile) {
           await editIpaApp(data)
        } else {
           await editNoIpaApp(data)
        }
    }
    

    const formik = useFormik({
        validateOnMount: false,
        enableReinitialize: true,
        initialValues:  defaulInitialValueForm,
        validationSchema: Yup.object().shape({
            appTitle: Yup.string()
                .required(t('required field')),
            path: Yup.string()
                .required(t('required field')),
            appversion: Yup.string("")
                .matches(
                    /^([0-9]+)\.([0-9]+)\.([0-9]+)(?:-([0-9A-Za-z-]+(?:\.[0-9A-Za-z-]+)*))?(?:\+[0-9A-Za-z-]+)?$/,
                    t('version regex error')
                ),
        }),
        onSubmit: async (values) => {
            if (edit) {
                console.log(values)
                await editApplication(values)
           } else {
               await createNewApplication(values)
           }
        }
    })



    useEffect(() => {
        const location = history.location;
        if (location.state && location.state.data) {
            setEdit(location.state.edit)
            const data = location.state.data
            console.log(data)
            if (location.state.edit) {
                const index = data.path.lastIndexOf('/')
                const pathApp = data.path.substring(0, index);
                const dtForm = {
                    appTitle: data.metadata.AppTitle,
                    path: pathApp,
                    appversion: data.metadata.AppVersion,
                    appDescription: data.metadata.AppDescription,
                    appLastUpdate: data.metadata.AppLastUpdate,
                    userGroup: data.metadata.usergroup
                }
                formik.setValues(dtForm)
                setData(data)
            } else {
                const dtForm = {
                    appTitle: '',
                    path: data.path,
                    appversion: '',
                    appDescription: '',
                    appLastUpdate: '',
                    userGroup: data.metadata.usergroup
                }
                formik.setValues(dtForm)
            }
        }
        return () => {}
    }, [history.location])



    return (
        <div className={classes.root}>
            <Container className={classes.container} maxWidth="lg">
                <form className={classes.form} onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button className={classes.buttonCancel}
                                size="large"
                                onClick={() => history.push('/')}>
                                {t('back')}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    id="appTitle"
                                    margin="normal"
                                    variant="outlined"
                                    label={t('app title')}
                                    value={formik.values.appTitle}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.appTitle && formik.touched.appTitle}
                                    helperText={(formik.errors.appTitle && formik.touched.appTitle) && formik.errors.appTitle}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    disabled={true}
                                    id="path"
                                    margin="normal"
                                    variant="outlined"
                                    label={t('app path')}
                                    value={formik.values.path}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.path && formik.touched.path}
                                    helperText={(formik.errors.path && formik.touched.path) && formik.errors.path}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    id="appversion"
                                    margin="normal"
                                    variant="outlined"
                                    label={t('app version')}
                                    value={formik.values.appversion}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.appversion && formik.touched.appversion}
                                    helperText={(formik.errors.appversion && formik.touched.appversion) && formik.errors.appversion}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    id="appDescription"
                                    margin="normal"
                                    variant="outlined"
                                    label={t('app description')}
                                    value={formik.values.appDescription}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    
                                />
                            </FormControl>
                        </Grid>
                        {/*<Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    id="appLastUpdate"
                                    margin="normal"
                                    variant="outlined"
                                    label={t('app last update')}
                                    value={formik.values.appLastUpdate}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    disabled={true}
                                    id="userGroup"
                                    margin="normal"
                                    variant="outlined"
                                    label={t('user group')}
                                    value={formik.values.userGroup}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </FormControl>
                        </Grid>
                        */}
                        <Grid item xs={12}>
                            <FileDropzone title={t('upload file')} onAcceptFiles={(data) => setFile(data)}  />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justify="flex-end" alignItems="center" spacing={4}>
                                <Grid item xs={12}>
                                    <SubmitButton
                                        disabled={(!file && !edit) }
                                        className={classes.submitButton}
                                        isSubmitting={formik.isSubmitting}>
                                        {edit ? t('save changes') : t('create')}
                                    </SubmitButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </div>
    )
}

CreateAppForm.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(CreateAppForm)
