const MuiTextField = {
    root: {
        width: '100%',
        margin: '10px 0px',
        "& .MuiOutlinedInput-root": {
            "& .Mui-disabled ": {
                color: "rgba(0, 0, 0, 0.45)"
            },
            '& fieldset': {
                borderColor: "rgba(0, 0, 0, 0.20)",
            },
            '&:hover fieldset': {
                borderColor: "rgba(176, 25, 26, 1)",
            },
            '&.Mui-focused fieldset': {
                borderColor: "rgba(176, 25, 26, 1)",
            },
            '&.Mui-disabled fieldset': {
                borderColor: "rgba(0, 0, 0, 0.10)",
            },
            "&.Mui-disabled svg": {
                color: "rgba(0, 0, 0, 0.45)"
            },
            "& svg": {
                borderColor: "rgba(176, 25, 26, 1)"
            },
        },
        "& .MuiInputLabel-root":{
            '&.Mui-disabled': {
                color: "rgba(0, 0, 0, 0.20)",
            }
        },
        "&:hover .MuiInputLabel-root":{
            color: "rgba(176, 25, 26, 1)",
        }
    },
};

export default MuiTextField;