import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import styles from './styles';
import {
    withStyles,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
    Button
} from '@material-ui/core';


const CreateFolderDialog = ({
    classes,
    open,
    onClose,
    onCreate
}) => {

    const { t } = useTranslation();

    const [value, setValue] = useState()


    const handleClose = () => {
        onClose()
    }

    const handleChange = (event) => {
        setValue(event.target.value)
    }


    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth className={classes.dialog}>
            <DialogContent>
                <DialogContentText>
                    {t('new folder')}
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="folderName"
                    label={t('folder name')}
                    type="text"
                    fullWidth
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t('cancel')}
                </Button>
                <Button onClick={() => onCreate(value)} color="secondary">
                    {t('create')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

CreateFolderDialog.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(CreateFolderDialog)
