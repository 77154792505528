import drop_arrow from "./drop-down-arrow.svg";
import pt_flag from "./pt-flag.svg";
import en_flag from "./en-flag.svg";
import es_flag from "./es-flag.svg";
import fr_flag from "./fr-flag.svg";

const Icons = {
    drop_arrow: drop_arrow,
    pt_flag: pt_flag,
    en_flag: en_flag,
    es_flag: es_flag,
    fr_flag: fr_flag
}

export default Icons;