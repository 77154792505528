
const  classes = theme => ({
    root: {},
    header: {
        backgroundColor: '#000000',
        padding: '0 30px',
        height: '100px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        '& h1': {
            color: 'white',
            marginBottom: '0',
            fontWeight: 700
        }
    },
    boxTitle: {
        textAlign: 'left',
        color: '#FFFFFF',
        '& h1': {
            fontWeight: 700,
            color: '#FFFFFF',
            lineHeight: 1.5
        },
        '& p': {
            fontStyle: 'italic'
        },
        [theme.breakpoints.down('sm')]: {
           display: 'none'
        }
    },
    logout: {
        marginLeft: 'auto',
        fontSize: '13px',
        color: 'white',
        '& svg': {
            marginRight: '10px'
        }
    },
})

export default classes;