import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Context } from './../../context';
import styles from './styles';
import {
    withStyles,
    IconButton
} from '@material-ui/core';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import LanguageMenu from '../../components/LanguageMenu';
import * as apiService from '../../utils/api';
import * as actionTypes from './../../actions';
import { AuthContext } from '../../context/auth/auth-context.js';



const Header = ({
    classes
}) => {

    const { t } = useTranslation();
    const history = useHistory();
    const { dispatch } = useContext(Context);
    const { signout } = useContext(AuthContext);


    const onClickLogoutHandle = async () => {
        signout()
    }


    return (
        <div className={classes.header}>
            <div className={classes.boxTitle}>
                <h1>AppRepo</h1>
                <p>Making App Distribution Fun And Easy!</p>
            </div>
            <div style={{marginLeft: 'auto', display: 'flex'}}>
                <LanguageMenu />
                <IconButton className={classes.logout} onClick={onClickLogoutHandle}>
                    <LogoutIcon />
                    {t('logout')}
                </IconButton>
            </div>
        </div>
    )
}

Header.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(Header)
