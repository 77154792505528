
const classes =  theme => ({
    root: {},
    thumbsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16
    },
    dropzone: {
        border: '1px dashed rgba(0, 0, 0, 0.12)',
        display: 'flex',
        outline: 'none',
        padding: '10px',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            cursor: 'pointer',
            opacity: 0.5,
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
        }
    },
    iconBox: {
        margin: '0 20px 10px',
        '& img': {
            width: '130px',
            objectFit: 'cover'
        }
    },
    infoBox: {
        '& h3': {
            fontWeight: 500,
            lineHeight: 1.167,
            marginBottom: '10px',
            textAlign: 'center'
        },
        '& p': {
            textAlign: 'center',
            fontWeight: 400,
            lineHeight: 1.5,
        }
    },
    fileInfoBox: {
        paddingTop: '10px',
        paddingLeft: '46px',
        fontWeight: 500,
    },
    fileInfo: {
        display: 'flex',
        color: '#cc3024',
        '& p': {
            marginRight: '10px',
            marginTop: '0',
            marginBottom: '0'
        },
        '& svg': {
            fontWeight: 700,
            color: 'red'
        }
    }
})

export default classes;

