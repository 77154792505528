import React, {  useState, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Paper, TextField, InputAdornment, IconButton, Button, CircularProgress, Link } from '@material-ui/core';
import { EmailRounded, LockRounded, Visibility, VisibilityOff } from '@material-ui/icons';

import { useHistory} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import { AuthContext } from '../../../context/auth/auth-context.js';
import { LanguageSelector } from '../../../components';
import Assets from '../../../assets';
import Styles from './style';


const useStyles = makeStyles(Styles);

const SignIn = () => {

    const { signin } = useContext(AuthContext);
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const [values, setValues] = useState({ showPassword: false });
    const { user } = getQueryStringsParams(['user']);

    const formik = useFormik({
        initialValues: {
            email: user ? user : '',
            password: ''
        },
        onSubmit: async values => {
            try {
                const response = await signin(values.email, values.password);
                const { challengeName } = response
                console.log(challengeName)
                if (challengeName) {
                    switch (challengeName) {
                        case 'NEW_PASSWORD_REQUIRED':
                            history.push('/auth/new-password-required');
                            break;
                        default:
                            break;
                    }
                } else {
                    history.push('/');
                }
            } catch (error) {
                throw error
            }
        },
    });

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    return (
        <Container className={classes.main}>
            <Container className={classes.header}>
                <LanguageSelector />
            </Container>
            <Container className={classes.content}>
                  <div className={classes.contentHeader}>
                    <div className={classes.logo}>
                        <img src={Assets.Images.cyc_logo_full_2} />
                    </div>
                    <div className={classes.contentHeaderTitle}>
                        <h1>AppRepo</h1>
                        <p>Making App Distribution Fun And Easy!</p>
                    </div>
                </div>

                <Paper className={classes.paper} elevation={0}>
                    <div className={classes.title}>{t('signin-text-title')}</div>
                    <div className={classes.subtitle}>{t('signin-text-subtitle')}</div>
                    <br />
                    <form onSubmit={formik.handleSubmit} className={classes.form}>
                        <TextField
                            id="email"
                            type='email'
                            placeholder={t('signin-input-email')}
                            variant="outlined"
                            InputProps={{ startAdornment: <EmailRounded className={classes.icons} /> }}
                            defaultValue={formik.initialValues.email}
                            onChange={formik.handleChange}
                            required
                        />
                        <TextField
                            id="password"
                            type={values.showPassword ? 'text' : 'password'}
                            placeholder={t('signin-input-password')}
                            variant="outlined"
                            InputProps={{
                                startAdornment: <LockRounded className={classes.icons} />,
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end">
                                        {values.showPassword ? <Visibility className={classes.icons} /> : <VisibilityOff className={classes.icons} />}
                                    </IconButton>
                                </InputAdornment>
                            }}
                            onChange={formik.handleChange}
                            required
                        />
                        <Button type='submit' variant="contained" disableElevation disabled={formik.isSubmitting}>{
                            formik.isSubmitting ? <CircularProgress size={24} className={classes.progress} /> : t('signin-btn-text')
                        }</Button>
                    </form>
                    <span className={classes.subtext}>{t('signin-text-subtext-1')} <Link component="button" onClick={() => history.push('/auth/recover-password')} className={classes.anchorText}>{t('signin-text-subtext-2')}</Link></span>
                </Paper>
            </Container>
            <Container className={classes.footer} maxWidth="xl">
                <div className={classes.subtext}>Powered by <img src={Assets.Images.cyc_logo_parcial_white} alt="Cycloid Logo" /></div>
                <span className={classes.subtext}>©2021 All rights reserved, <a href="https://www.cycloid.pt/pdf/Cycloid_Privacy%20Policy.pdf" className={classes.anchorText}>Privacy Policy</a></span>
            </Container>
            <div className={classes.overlay}></div>
        </Container>
    );
}

export default SignIn;




const getQueryStringsParams = (values) => {
    const urlParams = new URLSearchParams(window.location.search);
    let result = {}
    values.forEach(element => {
        result = {
            ...result,
            [element]: urlParams.get(element)
        }
    });
    return result;
}
