/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getApplication = /* GraphQL */ `
  query GetApplication($id: ID!) {
    getApplication(id: $id) {
      id
      AppTitle
      AppVersion
      AppDescription
      AppLastUpdate
      usergroup
      file {
        bucket
        region
        Key
      }
      createdAt
      updatedAt
    }
  }
`;
export const listApplications = /* GraphQL */ `
  query ListApplications(
    $filter: ModelApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApplications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        AppTitle
        AppVersion
        AppDescription
        AppLastUpdate
        usergroup
        file {
          bucket
          region
          Key
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
