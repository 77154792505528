
const classes =  theme => ({
    root: {
        zIndex: 1010,
        position: 'absolute',
        height: '100%',
        top: 0,
        right: 0,
        left: 0,
        '& h3': {
            marginTop: '10px'
        }
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.0)'
    },
    linearProgress: {
        width: '250px',
        heigth: '6px'
    },
    circularProgress: {
        width: '80px',
        heigth: '80px'
    }
})

export default classes;