import { Storage, Auth, API, graphqlOperation } from "aws-amplify";
import { listApplications } from "../../graphql/queries";
import { createApplication, deleteApplication, updateApplication } from "../../graphql/mutations"



/************************************************** AUTH *****************************************************/
export const login = async (data) =>
    await Auth.signIn(data.email, data.password)

export const getCurrentSession = async (data) =>
    await Auth.currentSession()

export const isUserAdmin = async () => {
    const resp = await Auth.currentAuthenticatedUser()
    let Listusergroups = resp.signInUserSession.accessToken.payload['cognito:groups']
    if (!Listusergroups) {
        return false
    }
    return Listusergroups.includes("Admins")
}

export const completeNewPassword = async (userData, password) => 
     await Auth.completeNewPassword(userData, password);

export const logout = async () =>
    await Auth.signOut()


/************************************************ APPLICATION ***********************************************/
export const getApplications = async () => 
    await API.graphql(graphqlOperation(listApplications, {limit: 10000}));

export const getFile = async (key, bucket) => {
    if (bucket) {
        return Storage.get(key, {
            bucket: bucket
        })
    } else {
        return Storage.get(key)
    }
}
   
export const removeItemStorage = async (key, bucket) => {
    if (bucket) {
        return Storage.remove(key, {
            bucket: bucket
        })
    } else {
        return  Storage.remove(key)
    }
}
   

export const uploadFile = async (fullPath, file, bucket) => {
    if (bucket) {
        return  Storage.put(fullPath, file, { 
            bucket: bucket
        })
    } else {
        return  Storage.put(fullPath, file, { contentType: file.type })
    }
}

export const createApp = async (data) => 
    await API.graphql(graphqlOperation(createApplication, { input: data }));

export const deleteApp = async (data) =>
    await API.graphql(graphqlOperation(deleteApplication, { input: data }));

export const updateApp = async (data) =>
    await API.graphql(graphqlOperation(updateApplication, { input: data }));

