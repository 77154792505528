import React, {  useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useDropzone } from 'react-dropzone';
import styles from './styles';
import {
    withStyles,
    Card,
    CardContent,
    IconButton
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

const MAX_SIZE_FILE = process.env.REACT_APP_PUBLIC_MAX_SIZE_FILE;


const FileDropzone = ({
    classes,
    title,
    onAcceptFiles
}) => {

    const { t } = useTranslation();

    const [files, setFiles] = useState([]);

    const { getRootProps, getInputProps } = useDropzone({
        maxFiles: 1,
        maxSize: MAX_SIZE_FILE,
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles)
            onAcceptFiles(acceptedFiles[0])
        },
        onDropRejected: rejectedFiles => {}
    });


    const handleRemoveFile = () => {
        setFiles([])
        onAcceptFiles(null)
    }

   

    return (
        <div className={classes.root}>
            <Card>
                <CardContent>
                    <div className={classes.cardContentRoot}>
                        <section className="container">
                            <div {...getRootProps({ className: classes.dropzone})}>
                                <input {...getInputProps()} />
                                <div className={classes.infoBox}>
                                    <h3>{t('select file')}</h3>
                                    <p>{t('drag files info')}</p>
                                </div>
                            </div>
                        </section>
                        <div className={classes.fileInfoBox}>
                            {files.length ?
                                <div className={classes.fileInfo}>
                                    <p>{files[0].name} - {`[${ Number(files[0].size/1000000).toFixed(2)} MB]`}</p>
                                    <IconButton aria-label="delete" size="small" onClick={handleRemoveFile}>
                                        <ClearIcon fontSize="inherit" />
                                    </IconButton>
                                </div> : <span>{t('no file selected')}</span>}
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}

FileDropzone.propTypes = {
    classes: PropTypes.object,
    title: PropTypes.string,
    onAcceptFiles: PropTypes.func
}

export default compose(
    withStyles(styles)
)(FileDropzone)
