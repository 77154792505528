
const classes = (theme) => ({
    root: {
        width: '100%',
        padding: '20px 20px',
        backgroundSize: 'cover',
        backgroundColor: 'white',
        height: '100%',
        display: 'flex',
        position: 'relative',
    },
    container: {
        zIndex: 2,
        height: '100%',
        backgroundColor: 'white',
        padding: '0 0'
    },
    logo: {
        width: '147px',
        height: '38px',
        marginBottom: 'auto',
        marginTop: 'auto',
        '& img': {
            width: '100%',
            height: '100%',
        }
    },
    content: { 
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    boxTitleAppList: {
        display: 'flex',
        borderBottom: '1px solid grey',
        padding: '20px 0',
        marginBottom: '20px',
        '& h2': {
            fontWeight: 600,
        },
        [theme.breakpoints.down('sm')]: {
            '& h2': {
                fontSize: '1.3rem',
            }
        }
    },
    boxContentAppList: {
        padding: '20px 20px',
        overflow: 'auto',
        [theme.breakpoints.down('sm')]: {
            padding: '5px 5px',
        }
    }
})

export default classes;