
const  classes = (theme) => ({
    root: {
        margin: '30px 0',
        display: 'flex',
        flexGrow: 1,
    },
    submitButton: {
        width: '100%' 
    },
    buttonCancel: {
        width: '100%',
        backgroundColor: 'rgba(30, 30, 30, 0.9)',
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: 'rgba(30, 30, 30, 0.6)'
        }
    }
})

export default classes;