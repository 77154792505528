import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme/sample';
import './assets/css/index.css';
import './i18n';
import ContextProvider from './context';
import DialogInfo from './components/DialogInfo'
import { ConfirmProvider } from 'material-ui-confirm';
import AuthContextProvider from './context/auth/auth-context';
import Layout from './layout';





function App() {
  return (
    <ConfirmProvider>
      <AuthContextProvider>
        <ContextProvider>
          <Suspense fallback={<div>Loading...</div>}>
            <BrowserRouter>
              <ThemeProvider theme={theme}>
                <Layout />
                <DialogInfo />
              </ThemeProvider>
            </BrowserRouter>
          </Suspense>
        </ContextProvider>
      </AuthContextProvider>
    </ConfirmProvider>
  );
}

export default App;
