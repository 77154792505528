/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:4e3823c9-f71a-4d01-b131-5e32edfcefce",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_z6TBOjRYh",
    "aws_user_pools_web_client_id": "6apgj4663qemegk6c16ij8jkb7",
    "oauth": {},
    "aws_user_files_s3_bucket": "cyc-app-repo-storage230035-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://zmvlautzy5attcwif24ekhmj3q.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket_public": "cyc-app-repo-ipa-files",
};


export default awsmobile;