import backgroundImage from './../../../assets/images/cy_bg_apprepo-login.jpg';

const Styles = theme => ({
    main: {
        maxWidth: '100vw',
        height: '100vh',
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column',
        backgroundSize: 'cover',
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: 'white',
        position: 'relative',
    },
    header: {
        maxWidth: '100vw',
        display: 'flex',
        position: 'relative',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    content: {
        zIndex: 10,
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '10px 0px 50px 0px',
        '& img': {
            height: '55px'
        }
    },
    form: {
        width: '100%'
    },
    paper: {
        maxWidth: '375px',
        boxShadow: 'none !important'
    },
    footer: {
        zIndex: 10,
        width: '100%',
        padding: '60px 0px 20px 0px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& div': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        '& div img': {
            width: '80px',
            padding: '0px 7px'
        }
    },
    icons: {
        fontSize: '19px',
        marginRight: '10px',
        color: '#B0191A'
    },
    progress: {
        color: '#B0191A'
    },
    title: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: '7px',
        ...theme.typography.title_h2,
    },
    subtitle: {
        width: '100%',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        ...theme.typography.subtitle,
    },
    subtext: {
        justifyContent: 'flex-start',
        ...theme.typography.label,
        zIndex: 10,
    },
    anchorText: {
        cursor: 'pointer',
        justifyContent: 'flex-start',
        ...theme.typography.anchor,
    },
    overlay: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        top: '0',
        zIndex: 1
    },
    contentHeader: {
        zIndex: 10,
        height: 'auto',
        padding: '20px 0px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxWidth: '650px',
        marginBottom: '100px',
        borderBottom: '1px solid grey',
        alignItems: 'center',
        '& h1': {
            zIndex: 10,
            color: 'white',
            fontWeight: 700
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '0px',
            flexDirection: 'column',
            borderBottom: '0px',
            padding: '0px',
        }
    },
    contentHeaderTitle: {
        marginLeft: 'auto',
        textAlign: 'right',
        color: '#FFFFFF',
        '& h1': {
            fontWeight: 700,
            color: '#FFFFFF',
            lineHeight: 1.5
        },
        '& p': {
            fontStyle: 'italic'
        },
        [theme.breakpoints.down('sm')]: {
            '& h1': {
                fontSize: '1.4rem'
            },
            '& p': {

            },
        }
    },
    logo: {
        width: 'auto',
        height: '55px',
        marginBottom: 'auto',
        marginTop: 'auto',
        '& img': {
            width: '100%',
            height: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            // display: 'none'
        }
    },
})

export default Styles;