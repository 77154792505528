const Styles = theme => ({
    root: {
        width: '60px',
        position: 'relative',
        zIndex: '10'
    },
    pooper: {
        width: 'auto',
        marginRight: '4px',
        '& .MuiPaper-root': {
            padding: '7px',
        }
    },
    secundaryButton: {
        margin: '7px 0px 0px 0px',
        height: '40px',
        color: 'red',
        '& .MuiButton-label':{
            color: 'rgba(0, 0, 0, 0.54);'
        }
    },
    menuItem: {
        color: '#474747 !important',
        borderRadius: "10px",
        margin: "4px 0px",
    }
})

export default Styles;