const Styles = theme => ({
    root: {
        width: '60px',
        position: 'relative',
        zIndex: '10'
    },
    button: {
        margin: '0px !important',
        color: '#ffffff',
    },
    pooper: {
        width: 'auto',
        marginRight: '4px'
    },
    paper:{
        padding: '10px'
    },
    menuList:{
        padding: '0px'
    },
    menuItem: {
        color: '#474747 !important',
        borderRadius: "10px",
        margin: "2px 0px",
        // "&:hover": {
        //     backgroundColor: '#c21a1a',
        //     color: "#ffffff !important",
        // }
    }
})

export default Styles;