
const classes =  theme => ({
    root: {},
    buttonMenu: {
        color: 'white',
        width: '200px',
        '& .MuiButton-startIcon': {
            width: '41px',
            height: '41px',
            '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'contain'
            }
        }
    },
    menuList: {
        width: '200px',
        '& li': {
            margin: '0 10px',
            '& span': {
                marginLeft: '10px'
            },
            '&:hover': {
                color: 'white',
                backgroundColor: 'rgba(105, 15, 15, 1)'
            }
        }
    },
    boxFlagLanguage: {
        width: '32px',
        height: '42px',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'contain'
        }
    }
})
export default classes