
const classes = theme => ({
    root: {},
    success: {
        backgroundColor: 'green',
        color: 'white'
    },
    error: {
        backgroundColor: 'red',
        color: 'white'
    }
})

export default classes;