import cyc_logo_full from './Cycloid_Tagline_RGB_Positive.svg';
import cyc_logo_full_2 from './cycloid_logo_2-gif';
import cyc_logo from './Cycloid_RGB_Icon.svg';
import cyc_logo_parcial from './Cycloid_RGB_Positive.svg';
import cyc_logo_parcial_white from './Cycloid_RGB_Positive_white.svg';


const Images = {
    cyc_logo_full: cyc_logo_full,
    cyc_logo_full_2: cyc_logo_full_2,
    cyc_logo: cyc_logo,
    cyc_logo_parcial: cyc_logo_parcial,
    cyc_logo_parcial_white: cyc_logo_parcial_white
}

export default Images;