import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PrivateRoutes } from '../routes';
import Styles from './style';
import Header from './Header'


const useStyles = makeStyles(Styles);

const PrivateLayout = () => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
      
            <div className={classes.contentContainer}>
                <div className={classes.toolbarContainer}>
                    <Header />
                </div>
                <div className={classes.viewContainer}>
                    <PrivateRoutes />
                </div>
            </div>
        </div>
    );
}

export default PrivateLayout;