import React, { useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Paper, TextField, Button, CircularProgress } from '@material-ui/core';
import { EmailRounded} from '@material-ui/icons';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import { AuthContext } from '../../../context/auth/auth-context.js';
import { LanguageSelector } from '../../../components';
import { useNotifications } from '../../../components/notification-snackbar';
import Assets from '../../../assets';
import Styles from './style';

const useStyles = makeStyles(Styles);

const RecoverPassword = () => {

    const { forgotPassword } = useContext(AuthContext);
    const { triggerNotification } = useNotifications();
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        onSubmit: async values => {
            try {
                const response = await forgotPassword(values.email);
                if(response){
                    triggerNotification("success", "notifications-recoverpassword-emailsent-message", "notifications-recoverpassword-emailsent-title");
                    history.push(`/auth/recover-password/confirm?user=${values.email}`);
                }
            } catch (error) {
                throw error;
            }
        },
    });

    return (
        <Container className={classes.main}>
            <Container className={classes.header}>
                <LanguageSelector />
            </Container>
            <Container className={classes.content}>
                <div className={classes.contentHeader}>
                    <div className={classes.logo}>
                        <img src={Assets.Images.cyc_logo_full_2} />
                    </div>
                    <div className={classes.contentHeaderTitle}>
                        <h1>AppRepo</h1>
                        <p>Making App Distribution Fun And Easy!</p>
                    </div>
                </div>
                <Paper className={classes.paper} elevation={0}>
                    <div className={classes.title}>{t('recoverpass-text-title')}</div>
                    <div className={classes.subtitle}>{t('recoverpass-text-subtitle')}</div>
                    <br />
                    <form onSubmit={formik.handleSubmit} className={classes.form}>
                        <TextField
                            id="email"
                            type='email'
                            placeholder={t('recoverpass-input-email')}
                            variant="outlined"
                            InputProps={{ startAdornment: <EmailRounded className={classes.icons} /> }}
                            onChange={formik.handleChange}
                            required
                        />
                        <Button type='submit' variant="contained" disableElevation disabled={formik.isSubmitting}>{
                            formik.isSubmitting ? <CircularProgress size={24} className={classes.progress} /> : t('recoverpass-btn-text')
                        }</Button>
                    </form>
                    <span className={classes.subtext}>{t('recoverpass-text-subtext-1')} <a onClick={() => history.push('/auth/login')} className={classes.anchorText}>{t('recoverpass-text-subtext-2')}</a></span>
                </Paper>
            </Container>
            <Container className={classes.footer} maxWidth="xl">
                <div className={classes.subtext}>Powered by <img src={Assets.Images.cyc_logo_parcial_white} alt="Cycloid Logo" /></div>
                <span className={classes.subtext}>©2021 All rights reserved, <a href="https://www.cycloid.pt/pdf/Cycloid_Privacy%20Policy.pdf" className={classes.anchorText}>Privacy Policy</a></span>
            </Container>
            <div className={classes.overlay}></div>

        </Container>
    );
}

export default RecoverPassword;













// const multiStyle = (...args) => {
//     let styles = {};
//     for (var i = 0; i < args.length; {
//         styles = { ...styles, ...args[i] };
//     }
//     console.log(args);
//     return styles;
// }

// const multiClassNames = (...args) => {
//     let classes = "";
//     for (var i = 0; i < args.length; {
//         classes += args[i] + " "
//     }
//     console.log(classes);
//     return classes;
// }
