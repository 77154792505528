import React, { useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Context } from './../../context';
import styles from './styles';
import { useConfirm } from 'material-ui-confirm';
import {
    withStyles,
    Container,
    Fab
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Tree from '../../components/Tree';
import LoadingScreen from './../../components/LoadingScreen';
import CreateFolderDialog from './../../components/CreateFolderDialog';
import PopupInfoApp from './components/PopupInfoApp';
import * as apiService from '../../utils/api';
import * as util from '../../utils/util';
import * as actionTypes from './../../actions';
import awsExports from "../../aws-exports";

const PUBLIC_STORAGE_URL = process.env.REACT_APP_PUBLIC_STORAGE_URL;
const PUBLIC_STORAGE_KEY = process.env.REACT_APP_PUBLIC_STORAGE_KEY;



const Home = ({
    classes
}) => {

    const { t } = useTranslation();
    const confirm = useConfirm();
    const history = useHistory();
    const { dispatch } = useContext(Context);
    const [loading, setLoading] = useState(true)
    const [loadingUpdate, setLoadingUpdate] = useState(false)
    const [apps, setApps] = useState([])
    const [data, setData] = useState([])
    const [openCreateFolder, setOpenCreateFolder] = useState(false)
    const [nodeSelected, setNodeSelected] = useState(null)
    const [showPopupInfoApp, setShowPopupInfoApp] = useState(false)



    const handleTreeAction = (event) => {
        switch (event.action) {
            case 'new folder':
                createFolder(event)
                break;
            
            case 'new file':
                createNewApp(event)
                break;
            
            case 'delete':
                deleteItem(event)
                break;
            
            case 'download':
                downloadApp(event)
                break;
            
            case 'edit':
                editApp(event)
                break;
            
            case 'info':
                openInfoApp(event)
                break;
        
            default:
                break;
        }
    }

  
    const createFolder = (data) => {
        setOpenCreateFolder(true)
        setNodeSelected(data.node)
    }

    const createFolderSubmit = async (value) => {
        setLoadingUpdate(true)
        setOpenCreateFolder(false)
        const newApp = {
            AppDescription: nodeSelected.metadata.AppDescription,
            AppLastUpdate: nodeSelected.metadata.AppLastUpdate,
            AppVersion: nodeSelected.metadata.AppVersion,
            AppTitle: nodeSelected.metadata.AppTitle,
            file: {
                Key: `${nodeSelected.path}/${value}`,
                bucket: awsExports.aws_user_files_s3_bucket,
                region: awsExports.aws_user_files_s3_bucket_region
            },
            usergroup: nodeSelected.metadata.usergroup
        }
        try {
            await apiService.createApp(newApp)
            getApps()
        } catch (error) {
            setLoadingUpdate(false)
        }
    }

    const createNewApp = (data) => {
        const dt = {
            path: data.node.path,
            metadata: data.node.metadata
        }
        history.push({
            pathname: 'apps/new',
            state: {data: dt, edit: false}
        })
    }

    const deleteItem = async (data) => {
        const description = data.node.isFolder ? 'delete folder' : 'delete file'
        let items = [data.node.metadata];
        getChildrenIdsNode(data.node, items)
        confirm({ title: t('confirm delete'), description: t(description) })
            .then(async () => {
                setLoadingUpdate(true)
                try {
                    await Promise.all(
                        items.map(async (item) => {
                            await apiService.deleteApp({ id: item.id })
                            const index = item.file.Key.lastIndexOf('.')
                            const extension = item.file.Key.substring(index + 1)
                            const isIpaFile = extension === 'plist'
                            if (isIpaFile) {
                                // Delete .plist in public storage
                                await apiService.removeItemStorage(item.file.Key, PUBLIC_STORAGE_KEY)

                                const index = item.file.Key.lastIndexOf('.')
                                let path = item.file.Key.substring(0, index)
                                path = `${path}.ipa`
                                // Delete .ipa in public storage
                                await apiService.removeItemStorage(path, PUBLIC_STORAGE_KEY)

                            } else {
                                await apiService.removeItemStorage(item.file.Key, null)
                            }
                        }));
                    getApps()
                    dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 1, title: t('success'), message: t(`${description} successfully`) } })
                } catch (error) {
                    setLoadingUpdate(false)
                    dispatch({ type: actionTypes.DIALOG_INFO, payload: { open: true, type: 0, title: t('error'), message: t(`${description} error`) } })
                }
            })
            .catch(() => { });
    }

    const downloadApp = async (event) => {
        try {
            let key = event.node.metadata.file.Key
            let url = ''
            if (event.node.extension === 'plist') {
                url = `${PUBLIC_STORAGE_URL}/${key}`
                url = `itms-services://?action=download-manifest&url=${url}`
                url = encodeURI(encodeURI(url));
            } else {
                url = await apiService.getFile(key)
            }
            window.location.assign(url);
        } catch (error) {}
        
    }

    const editApp = (event) => {
        history.push({
            pathname: `/apps/new`,
            state: { data: event.node, edit: true }
        })
    }
    
    const openInfoApp = (event) => {
        setNodeSelected(event.node)
        setShowPopupInfoApp(true)
    }

    const getChildrenIdsNode = (node, items) => {
        for (var i = 0; i < node.children.length; i++) {
            var child = node.children[i];
            getChildrenIdsNode(child, items);
            items.push(child.metadata)
        }
    }


    const getApps = async () => {
        try {
            const resp = await apiService.getApplications()
            const listApps = resp.data.listApplications.items
            const dt = util.listToTree(listApps)
            setData(dt)
            setApps(listApps)
            setLoadingUpdate(false)
        } catch (error) {
            setLoadingUpdate(false)
        }
    }

    


    useEffect(() => {
        const fetchApps = async () => {
            try {
                const resp = await apiService.getApplications()
                const listApps = resp.data.listApplications.items
                const dt = util.listToTree(listApps)
                setData(dt)
                setApps(listApps)
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        fetchApps()
        return () => {
            //
        }
    }, [])

    return (
        <div className={classes.root}>
            <Container className={classes.container} maxWidth="lg">
                <div className={classes.content}>
                    {loading ? <LoadingScreen /> : (
                         !apps.length ? (
                            <h1>{t('no apps available')}</h1>
                        ) : (
                                <>
                                    <div className={classes.boxTitleAppList}>
                                        <h2>{t('application list')}</h2>
                                        {false ? (
                                            <Fab
                                                style={{ marginLeft: 'auto' }}
                                                color="primary"
                                                size="large"
                                                aria-label="add"
                                                onClick={() => history.push('apps/new')}
                                            >
                                                <AddIcon />
                                            </Fab>
                                        ) : null}
                                        
                                    </div>
                                    <div className={classes.boxContentAppList}>
                                        {/*<div className={classes.searchBox}>
                                            <FormControl fullWidth >
                                                <TextField
                                                    id="text-field-search"
                                                    label={`${t('search')}...`}
                                                    margin="dense"
                                                    variant="outlined"
                                                    onChange={event => handleFilter(event.target.value)
                                                    } />
                                            </FormControl>
                                        </div>
                                        */}
                                        <div className={classes.treeBox}>
                                            {
                                                data.map((item, index) => (
                                                    <Tree
                                                        key={`tree-${index}`}
                                                        node={item}
                                                        onAction={handleTreeAction}
                                                    >
                                                    </Tree>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </>
                        )
                    )}
                </div>
            </Container>
            {openCreateFolder ? (
                <CreateFolderDialog
                    open={openCreateFolder}
                    onClose={() => setOpenCreateFolder(false)}
                    onCreate={value => createFolderSubmit(value)} />
            ) : null}
            {loadingUpdate ? <LoadingScreen type="circular" /> : null}
            {showPopupInfoApp ? <PopupInfoApp data={nodeSelected} open={showPopupInfoApp} onClose={() => setShowPopupInfoApp(false)}/> : null}
        </div>
    )
}

Home.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(Home)
