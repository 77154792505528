
const MuiTab = {
    root: {
        minWidth: '0px !important',
        padding: '0px',
        margin: '0px 20px 0px 0px'
    },
    wrapper: {
        display: 'flex',
        textTransform: 'none',
        flexDirection: 'row',
        '& .MuiSvgIcon-root':{
            marginRight: '7px'
        }
    },
    labelIcon: {
        minHeight: '0px'
    },
    indicator:{
        backgroundColor: 'pink'
    }
};

export default MuiTab;
