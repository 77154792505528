const MuiButton = {
    root: {
        width: '100%',
        height: '50px',
        margin: '25px 0px 25px 0px',
        borderRadius: '10px',
        textTransform: 'none',
    },
    label: {
        fontSize: '15px',
        color: '#ffffff'
    },
    contained: {
        backgroundColor: '#B0191A',
        "&:hover": {
            backgroundColor: '#c21a1a',
        }
    }
};

export default MuiButton;
