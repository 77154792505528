import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import CreateAppForm from "./../screens/CreateAppForm";
import PrivateRoute from './privateRoute';

import SignIn from "./../screens/Auth/SignIn";
import RecoverPassword from '../screens/Auth/RecoverPassword';
import NewPasswordRequired from '../screens/Auth/NewPasswordRequired';
import RecoverPasswordConfirm from '../screens/Auth/RecoverPasswordConfirm';

import Home from '../screens/Home';

export const PublicRoutes = () => {
    return (
        <Switch>
            <Route path="/auth/login" component={SignIn} exact />
            <Route path="/auth/recover-password" component={RecoverPassword} exact />
            <Route path="/auth/recover-password/confirm" component={RecoverPasswordConfirm} exact />
            <Route path="/auth/new-password-required" component={NewPasswordRequired} exact />  
            <Redirect from="/" to="/auth/login" />
        </Switch>
    )
}

export const PrivateRoutes = () => {
    return (
        <Switch>
            <PrivateRoute path="/" component={Home} exact />
            <PrivateRoute path="/apps/new" exact component={CreateAppForm} />
            <Redirect to="/"  />
        </Switch>
    )
}
