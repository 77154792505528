import React, { useContext } from 'react';

import { AuthContext } from '../context/auth/auth-context';

import PrivateLayout from './private-layout';
import PublicLayout from './public-layout';

import Notifications from '../components/notification-snackbar';

const Layout = () => {

    const { isAuthenticated, isLoading } = useContext(AuthContext);

    return (
        <>
            {  !isLoading ? (isAuthenticated ? <PrivateLayout /> : (<PublicLayout />)) : null }
            <Notifications />
        </>
    );
}

export default Layout;