import React, { useState, useEffect } from 'react';

import clsx from 'clsx';
import { compose } from 'recompose';

import { withStyles } from '@material-ui/core/styles';
import { Drawer, List, ListItem } from '@material-ui/core';
import { MenuRounded, ArrowBackIosRounded } from '@material-ui/icons';

import { useLocation, withRouter } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import MenuItem from './components/menu-item';
import MenuItemNest from './components/menu-item-nest';

import Styles from './style';
import Assets from '../../assets';

const SideMenu = ({ classes, history, structer }) => {

    let query = useMediaQuery('(max-width: 600px)')
    const location = useLocation();
    const { t } = useTranslation();
    const [state, setState] = useSideMenu()

    useEffect(() => {
        setState({ ...state, location: location.pathname });
        updateDrawerVariant(query);
    }, [location.pathname, query])

    const updateDrawerVariant = (value) => {
        if (value) {
            setState({ open: true, location: location.pathname, mobile: true, mobileOpen: false });
        } else {
            setState({ ...state, location: location.pathname, mobile: false, mobileOpen: false });
        }
    }

    const handleDrawer = () => {
        if (state.mobile) {
            setState({ ...state, mobileOpen: !state.open });
        } else {
            setState({ ...state, open: !state.open });
        }
    };

    return (
        <div className={clsx(classes.root, {
            [classes.rootPermanent]: !state.mobile,
            [classes.rootPersistance]: state.mobile,
        })}>
            <Drawer
                variant={state.mobile ? "temporary" : "permanent"}
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: state.open,
                    [classes.drawerClose]: !state.open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: state.open,
                        [classes.drawerClose]: !state.open,
                    }),
                }}
                open={state.mobileOpen}
                ModalProps={{ keepMounted: true }}>

                <div style={{ width: '100%', height: '70px', borderRadius: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', overflow: 'hidden' }}>

                    {/* <div style={{ width: '57px', backgroundColor: 'orange', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>

                    </div>
                    <div style={{ width: '100%', backgroundColor: 'orange', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
                        
                    </div> */}
                    <ListItem button onClick={() => handleDrawer()}>
                        {state.open ? <ArrowBackIosRounded /> : <MenuRounded />}
                        <img style={{ height: '40px', marginLeft: '20px' }} src={Assets.Images.cyc_logo_full} />
                    </ListItem>

                </div>

                <List classes={{ root: classes.list_root }}>
                    {
                        structer.map((prop) => {
                            return (
                                <MenuItem
                                    {...state}
                                    key={prop.id}
                                    label={t(prop.label)}
                                    icon={prop.icon}
                                    path={prop.path}
                                    onClick={() => history.push(prop.path)}>
                                    {
                                        prop.modules.length > 0 ?
                                            prop.modules.map((nest) => {
                                                return (
                                                    <MenuItemNest key={nest.id} label={t(nest.label)} path={nest.path} onClick={() => history.push(nest.path)} />
                                                );
                                            })
                                            : null
                                    }
                                </MenuItem>
                            );
                        })
                    }
                </List>


                <div style={{ width: '100%', height: '70px', borderRadius: '10px' }}>
                    {/* <ListItem>
                        <ListItemAvatar>
                            <Avatar>J</Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Vacation" secondary="July 20, 2014" />
                    </ListItem> */}
                </div>


            </Drawer>
        </div>
    );
}

export default compose(
    withStyles(Styles),
    withRouter
)(SideMenu);





export function useMediaQuery(query) {
    const [matches, setMatches] = useState(false);
    useEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        const listener = () => {
            setMatches(media.matches);
        };
        media.addEventListener("change", listener);
        return () => media.removeEventListener("change", listener);
    }, [matches, query]);
    return matches;
}


const sideMenuStatus = {
    state: { open: false, mobile: false, location: "", mobileOpen: false },
    setState(value) {
        this.state = value;
        this.setters.forEach(setter => setter(this.state));
    },
    setters: []
};

export const useSideMenu = () => {

    const [state, set] = useState(sideMenuStatus.state);
    if (!sideMenuStatus.setters.includes(set)) {
        sideMenuStatus.setters.push(set);
    }
    useEffect(() => () => {
        sideMenuStatus.setters = sideMenuStatus.setters.filter(setter => setter !== set)
    }, []);
    return [state, sideMenuStatus.setState];
}

sideMenuStatus.setState = sideMenuStatus.setState.bind(sideMenuStatus);