
const  classes = (theme) => ({
    root: {
        margin: '10px 20px 10px',
        [theme.breakpoints.down('sm')]: {
            margin: '5px 0px 5px',
        }
    },
    verticalBar: {
        width: '2px',
        height: '100%',
        backgroundColor: 'red'
    },
    treeRow: {
        borderBottom: '0.1rem solid #ddd',
        padding: '0.5rem',
        fontSize: '1rem', 
        backgroundColor: 'white', 
        display: 'flex',
        alignItems: 'center',
        minHeight: '75px',
        '& .tree-row-box-action': {
            marginLeft: 'auto',
            display: 'none'
        },
        '& .tree-row-box-free-action': {
            marginLeft: 'auto',
            display: 'none'
        },
        '&:hover': {
            '& .tree-row-box-action': {
                display: 'block'
            },
            '& .tree-row-box-free-action': {
                display: 'block'
            },
            '& .updateAtBox': {
                display: 'none',
            }
        },
        [theme.breakpoints.down('sm')]: {
            margin: '5px 0px 5px',
            padding: '0rem',
            fontSize: '0.8rem',
            flexWrap: 'wrap',
            '&:hover': {
                '& .updateAtBox': {
                    display: 'block !important',
                }
            },
            '& .tree-row-box-free-action': {
                display: 'block'
            }
        }
    },
    treeItem: {
        '& .MuiTreeItem-content': {
            color: 'black',
            '& .MuiTreeItem-label': {
                paddingLeft: '0px',
                backgroundColor: 'white',
                '&:hover': {
                    backgroundColor: 'white !important',
                },
                '&:focus': {
                    backgroundColor: 'white !important'
                }
            },
            '& .MuiTreeItem-iconContainer': {
                width: '0px',
                marginRight: '0px'
            }
        },
        '&.Mui-selected': {
            '& .MuiTreeItem-content .MuiTreeItem-label': {
                backgroundColor: 'white',
                '&:focus': {
                    backgroundColor: 'white'
                }
            }
        }
    },
    name: {
        marginLeft: '10px'
    },
    nameFile: {
        color: '#007bff',
        fontWeight: '600',
        '&:hover': {
            textDecoration: 'underline'
        },
        [theme.breakpoints.down('sm')]: {
           width: '60%'
        }
    },
    speedDial: {
        '& .MuiFab-root': {
            height: '36px',
            width: '36px'
        }
    },
    updatedAtBox: {
        fontWeight: 700,
        display: 'flex',
        marginLeft: 'auto',
        alignItems: 'center',
        fontSize: '13px',
        '& span': {
            fontStyle: 'italic',
            fontSize: '11px',
            fontWeight: 700,
            color: 'grey'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '13px',
            marginLeft: '10px',
            '& span': {
                fontSize: '10px',
            }
        }
    }
})

export default classes;