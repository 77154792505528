import React, { useState, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Paper, TextField, InputAdornment, IconButton, Button, CircularProgress } from '@material-ui/core';
import { LockRounded, Visibility, VisibilityOff, VpnKey } from '@material-ui/icons';

import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import { AuthContext } from '../../../context/auth/auth-context.js';
import { LanguageSelector } from '../../../components';
import { useNotifications } from '../../../components/notification-snackbar';
import Assets from '../../../assets';
import Styles from './style';

const useStyles = makeStyles(Styles);

const RecoverPasswordConfirm = () => {

    const { forgotPasswordSubmit, forgotPassword } = useContext(AuthContext);
    const { triggerNotification } = useNotifications();
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const [values, setValues] = useState({ showPassword: false, showConfirmPassword: false });
    const { code, user } = getQueryStringsParams(['code', 'user']);

    const formik = useFormik({
        initialValues: {
            code: code ? code : '',
            password: '',
            confirmPassword: ''
        },
        onSubmit: async values => {
            try {
                if (values.password !== values.confirmPassword) {
                    triggerNotification("warning", "notifications-default-MatchPassword-message", "notifications-default-MatchPassword-title")
                    return;
                }
                await forgotPasswordSubmit(user, values.code, values.confirmPassword);
                history.push(`/auth/login?user=${user}`);
            } catch (error) {
                throw error;
            }
        },
    });

    const resendVerificationCode = async() =>{
        try {
            const response = await forgotPassword(user);
            if(response){
                triggerNotification("success", "notifications-recoverpassword-emailsent-message", "notifications-recoverpassword-emailsent-title");
            }
        } catch (error) {
            throw error;
        }
    }

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };
    const handleClickShowConfirmPassword = () => {
        setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
    };

    return (
        <Container className={classes.main}>
            <Container className={classes.header}>
                <LanguageSelector />
            </Container>
            <Container className={classes.content}>
                <div className={classes.contentHeader}>
                    <div className={classes.logo}>
                        <img src={Assets.Images.cyc_logo_full_2} />
                    </div>
                    <div className={classes.contentHeaderTitle}>
                        <h1>AppRepo</h1>
                        <p>Making App Distribution Fun And Easy!</p>
                    </div>
                </div>
                <Paper className={classes.paper} elevation={0}>
                    <div className={classes.title}>{t('recoverpassconfirm-text-title')}</div>
                    <div className={classes.subtitle}>{t('recoverpassconfirm-text-subtitle')}</div>
                    <br />
                    <form onSubmit={formik.handleSubmit} className={classes.form}>
                        <TextField
                            id="code"
                            type='text'
                            placeholder={t('recoverpassconfirm-input-code')}
                            variant="outlined"
                            InputProps={{
                                startAdornment: <VpnKey className={classes.icons} />,
                            }}
                            defaultValue={formik.initialValues.code}
                            onChange={formik.handleChange}
                            error={formik.errors.password && formik.touched.password}
                            helperText={(formik.errors.password && formik.touched.password) && formik.errors.password}
                            required
                        />
                        <TextField
                            id="password"
                            type={values.showPassword ? 'text' : 'password'}
                            placeholder={t('recoverpassconfirm-input-password')}
                            variant="outlined"
                            InputProps={{
                                startAdornment: <LockRounded className={classes.icons} />,
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end">
                                        {values.showPassword ? <Visibility className={classes.icons} /> : <VisibilityOff className={classes.icons} />}
                                    </IconButton>
                                </InputAdornment>
                            }}
                            onChange={formik.handleChange}
                            error={formik.errors.password && formik.touched.password}
                            helperText={(formik.errors.password && formik.touched.password) && formik.errors.password}
                            required
                        />
                        <TextField
                            id="confirmPassword"
                            type={values.showConfirmPassword ? 'text' : 'password'}
                            placeholder={t('recoverpassconfirm-input-confirm-password')}
                            variant="outlined"
                            InputProps={{
                                startAdornment: <LockRounded className={classes.icons} />,
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowConfirmPassword}
                                        edge="end">
                                        {values.showConfirmPassword ? <Visibility className={classes.icons} /> : <VisibilityOff className={classes.icons} />}
                                    </IconButton>
                                </InputAdornment>
                            }}
                            onChange={formik.handleChange}
                            error={formik.errors.confirmPassword && formik.touched.confirmPassword}
                            helperText={(formik.errors.confirmPassword && formik.touched.confirmPassword) && formik.errors.confirmPassword}
                            required
                        />
                        <Button type='submit' variant="contained" disableElevation disabled={formik.isSubmitting}>{
                            formik.isSubmitting ? <CircularProgress size={24} className={classes.progress} /> : t('recoverpassconfirm-btn-text')
                        }</Button>
                    </form>
                    <span className={classes.subtext}>{t('recoverpassconfirm-text-subtext-1')} <a onClick={resendVerificationCode} className={classes.anchorText}>{t('recoverpassconfirm-text-subtext-2')}</a></span>
                </Paper>
            </Container>
            <Container className={classes.footer} maxWidth="xl">
                <div className={classes.subtext}>Powered by <img src={Assets.Images.cyc_logo_parcial_white} alt="Cycloid Logo" /></div>
                <span className={classes.subtext}>©2021 All rights reserved, <a href="https://www.cycloid.pt/pdf/Cycloid_Privacy%20Policy.pdf" className={classes.anchorText}>Privacy Policy</a></span>
            </Container>
            <div className={classes.overlay}></div>
        </Container>
    );
}

export default RecoverPasswordConfirm;




// Util

const getQueryStringsParams = (values) => {
    const urlParams = new URLSearchParams(window.location.search);
    let result = {}
    values.forEach(element => {
        result = {
            ...result,
            [element]: urlParams.get(element)
        }
    });
    return result;
}